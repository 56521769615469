import HomeCard from "../../../components/Card/HomeCard";
import profile from "../../../assets/home_about_me.jpeg";

import "./index.scss";

function HomeAboutMe() {
	const aboutMeHeader = "Über Mich",
		aboutMeText = [
			"Mein Name ist Katharina Gleixner und ich bin die Inhaberin von \u00C6STHETIK.. Seit meiner Ausbildung zur zertifizierten Wimpernstylistin im Jahr 2020, habe ich es mir zur Aufgabe gemacht, die natürliche Schönheit jeder Kundin hervorzuheben.",
		];

	return (
		<div className="homeAboutMeContainer">
			<HomeCard
				image={{
					src: profile,
					alt: "Katharina Gleixner",
					location: "left",
					type: "image",
				}}
				content={{
					header: aboutMeHeader,
					text: aboutMeText,
					button: { theme: "light", text: "Mehr" },
				}}
				nav="uber-mich"
				settings={{
					extraPlaced: false,
					headerPlaced: true,
					buttonPlaced: true,
					imageSize: "mid",
				}}></HomeCard>
		</div>
	);
}

export default HomeAboutMe;
