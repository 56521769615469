import "./index.scss";

function AGB() {
	return (
		<div>
			<div className="agbContainer">
				<div className="agbHeader">Allgemeine Geschäftsbedingungen (AGB)</div>
				<div className="agbTextContainer">
					<div>
						<div className="agbTextHeader">1. Geltungsbereich</div>
						<div className="agbText">
							<div>1.1</div>
							<div>
								Die folgenden Allgemeinen Geschäftsbedingungen (nachfolgend
								"AGB") regeln das Vertragsverhältnis zwischen{" "}
								<span>&#198;</span>
								STHETIK. KATHARINA GLEIXNER (nachfolgend "Anbieterin" oder
								"ich") und den Kundinnen (nachfolgend "Kundin" oder "Du"), die
								Dienstleistungen von <span>&#198;</span>STHETIK. KATHARINA
								GLEIXNER in Anspruch nehmen.
							</div>
						</div>
						<div className="agbText">
							<div>1.2</div>
							<div>
								Abweichende Geschäftsbedingungen der Kundin finden keine
								Anwendung, es sei denn, die Anbieterin hat diesen ausdrücklich
								schriftlich zugestimmt.
							</div>
						</div>
					</div>
					<div>
						<div className="agbTextHeader">2. Leistungsumfang</div>
						<div className="agbText">
							<div>2.1</div>
							<div>
								Die Anbieterin bietet Wimpernverlängerungsbehandlungen an.
							</div>
						</div>
						<div className="agbText">
							<div>2.2</div>
							<div>
								Die Leistungen werden nach individueller Absprache und
								entsprechend den Wünschen der Kundin erbracht.
							</div>
						</div>
						<div className="agbText">
							<div>2.3</div>
							<div>
								Die Anbieterin verwendet hochwertige Produkte und Techniken, um
								ein optimales Ergebnis zu erzielen.
							</div>
						</div>
					</div>
					<div>
						<div className="agbTextHeader">3. Terminvereinbarung</div>
						<div className="agbText">
							<div>3.1</div>
							<div>
								Termine können vor Ort, telefonisch oder Online vereinbart
								werden.
							</div>
						</div>
						<div className="agbText">
							<div>3.2</div>
							<div>
								Die Kundin verpflichtet sich, pünktlich zu ihrem vereinbarten
								Termin zu erscheinen. Bei Verspätung kann die Behandlungsdauer
								verkürzt werden oder der Termin muss möglicherweise verschoben
								werden.
							</div>
						</div>
					</div>
					<div>
						<div className="agbTextHeader">
							4. Preise und Zahlungsbedingungen
						</div>
						<div className="agbText">
							<div>4.1</div>
							<div>
								Die Preise für die Dienstleistungen richten sich nach dem
								individuellen Leistungsumfang und werden vorab mit der Kundin
								vereinbart. Es ist immer eine aktuelle Preisliste vorhanden.
							</div>
						</div>
						<div className="agbText">
							<div>4.2</div>
							<div>
								Etwaige Sondervereinbarungen müssen schriftlich festgehalten
								werden.
							</div>
						</div>
						<div className="agbText">
							<div>4.3</div>
							<div>
								Die Zahlung erfolgt in bar, direkt nach Erbringung der
								Dienstleistung.
							</div>
						</div>
					</div>
					<div>
						<div className="agbTextHeader">5. Stornierung</div>
						<div className="agbText">
							<div>5.1</div>
							<div>
								Stornierungen müssen mindestens 24 Stunden vor dem vereinbarten
								Termin erfolgen. Gemäß § 615 BGB hat die Anbieterin die
								Möglichkeit, für verspäteter Stornierung oder Nichterscheinen
								eine Ausfallgebühr in Höhe von 100% des Terminpreises zu
								erheben. Diese Regelung gilt unabhängig vom Grund einer Absage.
								Eine Stornierung ist ausschließlich während der Arbeitstage
								(Mo-Sa, außer feiertags) bis 24 Stunden vor dem Termin
								kostenfrei.
							</div>
						</div>
					</div>
					<div>
						<div className="agbTextHeader">6. Haftungsausschluss</div>
						<div className="agbText">
							<div>6.1</div>
							<div>
								Die Anbieterin haftet nicht für Schäden oder Verluste, die durch
								unsachgemäße Pflege der behandelten Wimpern entstehen.
							</div>
						</div>
						<div className="agbText">
							<div>6.2</div>
							<div>
								Die Kundin ist dafür verantwortlich, alle relevanten
								gesundheitlichen Informationen vor der Behandlung mitzuteilen,
								insbesondere Allergien oder Hautempfindlichkeiten.
							</div>
						</div>
						<div className="agbText">
							<div>6.3</div>
							<div>
								Die Kundin ist dafür verantwortlich, während der Behandlung
								etwaige Unannehmlichkeiten direkt der Anbieterin zu melden. Die
								Anbieterin haftet nicht für rote Augen oder Allergische
								Reaktionen.
							</div>
						</div>
					</div>
					<div>
						<div className="agbTextHeader">7. Datenschutz</div>
						<div className="agbText">
							<div>7.1</div>
							<div>
								Die Anbieterin behandelt alle persönlichen Daten der Kundin
								vertraulich und gemäß den geltenden Datenschutzbestimmungen.
							</div>
						</div>
						<div className="agbText">
							<div>7.2</div>
							<div>
								Die Kundin erklärt sich damit einverstanden, dass ihre Daten zum
								Zwecke der Behandlung und Terminvereinbarung gespeichert und
								verarbeitet werden.
							</div>
						</div>
					</div>
					<div>
						<div className="agbTextHeader">8. Schlussbestimmungen</div>
						<div className="agbText">
							<div>8.1</div>
							<div>
								Sollten einzelne Bestimmungen dieser AGB unwirksam sein oder
								werden, berührt dies nicht die Wirksamkeit der übrigen
								Bestimmungen.
							</div>
						</div>
						<div className="agbText">
							<div>8.2</div>
							<div>
								Diese AGB und das Vertragsverhältnis zwischen der Anbieterin und
								der Kundin unterliegen dem deutschen Recht.
							</div>
						</div>
						<div className="agbText">
							<div>8.3</div>
							<div>
								Gerichtsstand für alle Streitigkeiten aus oder im Zusammenhang
								mit diesen AGB und dem Vertragsverhältnis ist der Sitz der
								Anbieterin, soweit gesetzlich zulässig.
							</div>
						</div>
					</div>
				</div>
				<div className="agbBottomContainer">
					<div>Stand: 25.03.2024</div>
					<div>Allgemeine Geschäftsbedingungen (AGB)</div>
					<div>
						<span>&#198;</span>
						STHETIK. KATHARINA GLEIXNER
					</div>
				</div>
			</div>
		</div>
	);
}

export default AGB;
