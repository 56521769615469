import Accordion from "react-bootstrap/Accordion";

import "./index.scss";

function FAQs() {
	return (
		<div className="faqContainer">
			<div className="faqHeaderContainer backgroundDark">
				<div className="faqHeader fontHeaderBig fontLight">FAQs</div>
			</div>
			<div className="faqContentContainer fontText fontBlack">
				<Accordion alwaysOpen>
					<Accordion.Item eventKey="0">
						<Accordion.Header>
							Was sind die Vorteile einer Wimpernverlängerung?
						</Accordion.Header>
						<Accordion.Body>
							Eine Wimpernverlängerung bietet schnellere, langanhaltende und
							pflegeleichte Ergebnisse im Vergleich zur täglichen
							Mascara-Anwendung. Selbst empfindliche Augen können von dieser
							Behandlung profitieren. Zusätzlich kann sie die Augenform optisch
							korrigieren und mehr Ausdruck verleihen, wodurch ein gesteigertes
							Wohlbefinden erzielt werden kann.
						</Accordion.Body>
					</Accordion.Item>
					<Accordion.Item eventKey="1">
						<Accordion.Header>
							Können meine natürlichen Wimpern Schaden nehmen?
						</Accordion.Header>
						<Accordion.Body>
							Eine Wimpernverlängerung beschädigt Deine natürlichen Wimpern
							nicht, da die Extensions einzeln und schonend an Deinen Wimpern
							befestigt werden. Hochwertige Materialien und die richtige
							Gewichts- und Längenwahl verhindern Überlastung oder Schäden.
						</Accordion.Body>
					</Accordion.Item>
					<Accordion.Item eventKey="2">
						<Accordion.Header>
							Ich weiß nicht genau, was zu mir passt. Wie finde ich das raus?
						</Accordion.Header>
						<Accordion.Body>
							Vor deinem Termin sende mir einfach ein Foto von Dir und ein paar
							Beispielbilder von Wimpern, die Dir gefallen. Mit diesem Material
							und meiner Expertise finden wir gemeinsam den perfekten Look für
							Dich. Mir ist es wichtig, Deine Augen- und Gesichtsform zu
							berücksichtigen, um einen Look zu kreieren, der Deine natürliche
							Schönheit unterstreicht und individuell an Dich angepasst ist. Ich
							nehme mir die Zeit, Deinen einzigartigen Look zu finden und Dich
							umfassend zu beraten.
						</Accordion.Body>
					</Accordion.Item>
					<Accordion.Item eventKey="3">
						<Accordion.Header>
							Warum muss ich meine Wimpernverlängerung auffüllen lassen?
						</Accordion.Header>
						<Accordion.Body>
							Deine Wimpern wachsen in einem bestimmten Zyklus von etwa 6-8
							Wochen. Während dieses Zyklus fallen alte Wimpern aus und Neue
							wachsen nach - sie erneuern sich dadurch immer wieder. Deine
							Wimpernverlängerung wird daher im Laufe der Zeit lichter, da die
							Extensions an Deinen natürlichen Wimpern schonen befestigt sind,
							fallen sie mit diesen aus. Regelmäßiges Auffüllen verlängert die
							Gesamthaltbarkeit deiner Wimpernverlängerung und garantiert eine
							Gesunderhaltung Deiner natürlichen Wimpern.
						</Accordion.Body>
					</Accordion.Item>
					<Accordion.Item eventKey="4">
						<Accordion.Header>
							Wie lange hält eine Wimpernverlängerung?
						</Accordion.Header>
						<Accordion.Body>
							Eine Wimpernverlängerung kann unterschiedlich lange halten. Hier
							spielen einige Faktoren eine Rolle, beispielsweise der
							Wachstumszyklus Deiner natürlichen Wimpern, Hautbeschaffenheit,
							etc. Die meisten Kundinnen kommen dreiwöchentlich zum Auffüllen
							der Wimpern. Gemeinsam finden wir den richtigen Rhythmus für Dich.
						</Accordion.Body>
					</Accordion.Item>
					<Accordion.Item eventKey="5">
						<Accordion.Header>
							Was sind die Vorteile der innovativen UV-Technologie?
						</Accordion.Header>
						<Accordion.Body>
							Die UV-Wimpernverlängerung verkürzt die Behandlungszeit und bietet
							sofort haltbare, sowie pflegeleichte Wimpern. Die stärkere Haftung
							sorgt dafür, dass die Wimpernverlängerung deutlich länger hält.
							Sie reduziert das Risiko von Allergien und Augenreizungen, was den
							Tragekomfort erhöht. Ich arbeite ausschließlich mit Firmen
							zusammen, die an dieser innovativen Technologie jahrelang
							geforscht haben und höchste Sicherheitsstandards erfüllen.
						</Accordion.Body>
					</Accordion.Item>
					<Accordion.Item eventKey="6">
						<Accordion.Header>
							Ich hatte noch nie eine Wimpernverlängerung. Was muss ich
							beachten?
						</Accordion.Header>
						<Accordion.Body>
							Ich habe einige Kundinnen, welche noch nie eine
							Wimpernverlängerung hatten und bei mir die ersten Erfahrungen
							gesammelt haben. Vor der Behandlung gebe ich Dir kleine Tipps, wie
							Du dich auf Deine neuen Wimpern vorbereiten kannst. Ich nehme mir
							ausführlich Zeit für Dich und erkläre Dir jeden Schritt der
							Behandlung. Wir besprechen gemeinsam Deine Wünsche und finden den
							perfekten Look für Dich.
						</Accordion.Body>
					</Accordion.Item>
					<Accordion.Item eventKey="7">
						<Accordion.Header>
							Ich war Kundin in einem anderen Studio. Kann ich zu Dir wechseln?
						</Accordion.Header>
						<Accordion.Body>
							Ja, immer gerne. Ob wir Deine Wimpernverlängerung auffüllen können
							oder ein Neuset nötig ist, kann ich allerdings erst vor Ort in
							meinem Studio sagen, wenn ich Deine Wimpern gesehen habe.
						</Accordion.Body>
					</Accordion.Item>
					<Accordion.Item eventKey="8">
						<Accordion.Header>
							Wie kann ich einen Termin buchen?
						</Accordion.Header>
						<Accordion.Body>
							Du kannst ganz einfach bei mir einen Termin buchen. Entweder über
							meinen Instagram Account @kg.aestheticlashes oder per WhatsApp
							unter +491631320591.
						</Accordion.Body>
					</Accordion.Item>
				</Accordion>
			</div>
		</div>
	);
}

export default FAQs;
