import PriceListCard from "../../components/Card/PriceListCard";
import classic from "../../assets/pricelist_classic.jpeg";
import full from "../../assets/pricelist_full.jpeg";
import natural from "../../assets/pricelist_natural.jpeg";
import wet from "../../assets/pricelist_wet.jpg";
import wispy from "../../assets/pricelist_wispy.jpg";

import "./index.scss";
import SectionHeader from "../../components/SectionHeader";

function PriceList() {
	const itemsTech = [
		{
			image: {
				src: classic,
				alt: "Classic Volumen",
			},
			content: {
				header: "Classic Volumen",
				text: [
					"Auch bekannt als Mascara-Look oder 1:1-Technik.",
					"Bei dieser Technik wird jede deiner natürlichen Wimpern mit einer einzelnen Extension verlängert. Perfekt für dich, wenn du einen klassischen, eleganten Look wünschst – wie frisch aufgetragenes Mascara, den ganzen Tag.",
				],
			},
			priceList: [
				{ title: "Neuset", prices: [98] },
				{
					title: "Auffüllen bis Tag 21",
					prices: [59],
				},
				{
					title: "Auffüllen bis Tag 28",
					prices: [79],
				},
			],
		},
		{
			image: {
				src: natural,
				alt: "Natural Volumen",
			},
			content: {
				header: "Natural Volumen",
				text: [
					"2D-6D – Natürliches, wunderschönes Volumen.",
					"Diese Methode zaubert ein voluminöseres Ergebnis, ohne „zu viel“ zu wirken. Pro natürliche Wimper werden zwei bis sechs leichte Extensions appliziert. Ideal für Dich, wenn du einen volleren, aber dennoch dezenten Look möchtest, der Deine natürliche Schönheit unterstreicht.",
				],
			},
			priceList: [
				{ title: "Neuset", prices: [118] },
				{
					title: "Auffüllen bis Tag 21",
					prices: [69],
				},
				{
					title: "Auffüllen bis Tag 28",
					prices: [89],
				},
			],
		},
		{
			image: {
				src: full,
				alt: "Full Aesthetic Volumen",
			},
			content: {
				header: "Full Aesthetic Volumen",
				text: [
					"7D-10D – Voluminöser und dennoch ästhetischer Look.",
					" Für Dich, wenn du es gerne voluminöser magst! Diese Technik ermöglicht es, sieben bis zehn feine Extensions pro natürlicher Wimper anzubringen. Das Ergebnis ist ein atemberaubendes Volumen, das dennoch elegant und ästhetisch bleibt – ideal, wenn du einfach „mehr“ willst.",
				],
			},
			priceList: [
				{ title: "Neuset", prices: [138] },
				{
					title: "Auffüllen bis Tag 21",
					prices: [79],
				},
				{
					title: "Auffüllen bis Tag 28",
					prices: [99],
				},
			],
		},
	];
	const itemsModern = [
		{
			image: {
				src: wet,
				alt: "Wet Look",
			},
			content: {
				header: "Wet Look",
				text: [
					"💧 Moderner und frischer Look.",
					"Diese Technik imitiert den Effekt von nassen, frisch getuschten Wimpern. Perfekt für dich, wenn du einen trendigen, intensiven Look möchtest, der deinen Augen Ausdruckskraft verleiht.",
				],
			},
			priceList: [],
		},
		{
			image: {
				src: wispy,
				alt: "Wet & Wispy",
			},
			content: {
				header: "Wet & Wispy",
				text: [
					"🍃 Leichter, natürlicher Look.",
					"Hierbei werden unterschiedlich lange Extensions kombiniert, um einen federartigen, unregelmäßigen Effekt zu erzeugen. Ideal für dich, wenn du einen modernen, texturierten Look bevorzugst, der natürlich und zugleich auffällig ist.",
				],
			},
			priceList: [],
		},
	];
	const renderTechList = itemsTech.map((item) => {
		return (
			<PriceListCard
				key={item.content.header}
				image={{
					src: item.image.src,
					alt: item.image.alt,
				}}
				content={{
					header: item.content.header,
					text: item.content.text,
					extra: null,
				}}
				priceList={item.priceList}></PriceListCard>
		);
	});
	const renderModernList = itemsModern.map((item) => {
		return (
			<PriceListCard
				key={item.content.header}
				image={{
					src: item.image.src,
					alt: item.image.alt,
				}}
				content={{
					header: item.content.header,
					text: item.content.text,
					extra: null,
				}}
				priceList={item.priceList}></PriceListCard>
		);
	});

	return (
		<div className="priceListContainer">
			<div className="priceListHeaderContainer backgroundDark">
				<div className="priceListHeader fontHeaderBig fontLight">
					Preisliste
				</div>
			</div>
			<div className="priceListExtraInfo fontExtraInfo fontBlack">
				Unabhängig davon, welchen Look Du bevorzugst, bei mir bist Du in besten
				Händen. Ich nehme mir die Zeit, Deine Augen- und Gesichtsform zu
				analysieren und einen Look zu kreieren, der Deine natürliche Schönheit
				unterstreicht und individuell an Dich angepasst ist. Lass uns gemeinsam
				Deinen perfekten Wimpernlook kreieren und vereinbare gleich heute noch
				einen Termin!
			</div>
			<div className="priceListContentContainer">
				<div className="priceListContentHeader">
					<SectionHeader
						header="Erläuterung der Techniken:"
						theme="light"
						direction="left"></SectionHeader>
				</div>
				{renderTechList}
			</div>
			<div className="priceListContentContainer">
				<div className="priceListContentHeader">
					<SectionHeader
						header="Aktuell moderne Looks, die Du bei AESTHETIK. auf Wunsch zusätzlich erhältst:"
						theme="light"
						direction="left"></SectionHeader>
				</div>
				{renderModernList}
			</div>
		</div>
	);
}

export default PriceList;
