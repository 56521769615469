import { NavLink } from "react-router-dom";
import { type HomeCardType } from "../../../lib/types";
import Button from "../../Button";
import SectionHeader from "../../SectionHeader";

import "./index.scss";

function HomeCard({ image, content, nav, settings }: HomeCardType) {
	const imgLocation = image.location === "left" ? "imageLeft" : "imageRight";
	const resultText = content.text.map((text) => {
		let result = <>{text}</>;
		if (text.includes("\u00C6STHETIK.")) {
			let splits = text.split("\u00C6STHETIK.");
			result = (
				<>
					{splits[0]}
					<strong>
						<span>&#198;</span>STHETIK.
					</strong>
					{splits[1]}
				</>
			);
		}
		return (
			<>
				{result} <br /> <br />
			</>
		);
	});

	return (
		<div className={"homeCardContainer " + imgLocation}>
			<div className="homeCardImageContainer">
				{image.type === "image" ? (
					<img
						className={settings.imageSize}
						src={image.src}
						alt={image.alt}
						loading="lazy"
						decoding="async"
					/>
				) : (
					<video controls autoPlay loop muted playsInline>
						<source src={image.src} type="video/mp4" />
					</video>
				)}
			</div>
			<div className="homeCardContentContainer fontText">
				{settings.extraPlaced ? (
					<div className={content.extra ? "homeCardExtraContainer" : ""}>
						{content.extra}
					</div>
				) : null}
				<div>
					<div className="homeCardContentHeader">
						{settings.headerPlaced ? (
							<SectionHeader
								header={content.header!}
								theme="light"
								direction="left"></SectionHeader>
						) : null}
					</div>
					<div className="homeCardContentText fontText">{resultText}</div>
					<div className="homeCardContentButton">
						{settings.buttonPlaced && content.button ? (
							<NavLink to={nav!} style={{ textDecoration: "none" }}>
								<Button
									theme={content.button.theme}
									text={content.button.text}
									switchTheme="switchOnSmall"></Button>
							</NavLink>
						) : null}
					</div>
				</div>
			</div>
		</div>
	);
}

export default HomeCard;
