import FaqCard from "../../../components/Card/FaqCard";
import SectionHeader from "../../../components/SectionHeader";

import "./index.scss";

function HomeFAQs() {
	const items = [
		{
			header: "Was sind die Vorteile einer Wimpernverlängerung?",
			text: "Eine Wimpernverlängerung bietet schnellere, langanhaltende und pflegeleichte Ergebnisse im Vergleich zur täglichen Mascara-Anwendung. Selbst empfindliche Augen können von dieser Behandlung profitieren. Zusätzlich kann sie die Augenform optisch korrigieren und mehr Ausdruck verleihen, wodurch ein gesteigertes Wohlbefinden erzielt werden kann.",
		},
		{
			header: "Können meine natürlichen Wimpern Schaden nehmen?",
			text: "Eine Wimpernverlängerung beschädigt Deine natürlichen Wimpern nicht, da die Extensions einzeln und schonend an Deinen Wimpern befestigt werden. Hochwertige Materialien und die richtige Gewichts- und Längenwahl verhindern Überlastung oder Schäden. Dies ist mir besonders wichtig, weshalb ich viel Wert auf eine ausführliche Beratung lege.",
		},
		{
			header: "Wie lange hält eine Wimpernverlängerung?",
			text: "Eine Wimpernverlängerung kann unterschiedlich lange halten. Die meisten Kundinnen kommen dreiwöchentlich zum Auffüllen der Wimpern. Gemeinsam finden wir den richtigen Rhythmus für Dich.",
		},
		{
			header: "Was sind die Vorteile der innovativen UV-Technologie?",
			text: "Die UV-Wimpernverlängerung verkürzt die Behandlungszeit und bietet sofort haltbare, sowie pflegeleichte Wimpern. Die stärkere Haftung sorgt dafür, dass die Wimpernverlängerung deutlich länger hält. Sie reduziert das Risiko von Allergien und Augenreizungen, was den Tragekomfort erhöht. Ich arbeite ausschließlich mit Firmen zusammen, die an dieser innovativen Technologie jahrelang geforscht haben und höchste Sicherheitsstandards erfüllen.",
		},
	];

	const renderFaqs = items.map((item, index) => {
		return (
			<FaqCard
				key={"faq_card_" + item.header}
				content={{
					header: item.header,
					text: [item.text],
					button: { theme: "light", text: "Mehr" },
				}}></FaqCard>
		);
	});

	return (
		<div className="homeFaqsContainer">
			<div className="homeFaqsSectionHeader">
				<SectionHeader
					header="FAQs"
					theme="light"
					direction="left"></SectionHeader>
			</div>
			<div className="homeFaqsCardContainer">{renderFaqs}</div>
		</div>
	);
}

export default HomeFAQs;
