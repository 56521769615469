import HomeCard from "../../../components/Card/HomeCard";
import video from "../../../assets/services_video.mp4";

import "./index.scss";

function HomeServices() {
	const servicesHeader = "Was Biete Ich",
		servicesText = [
			"Bei \u00C6STHETIK. liegt mir besonders am Herzen, ausschließlich hochwertige Produkte von renommierten Firmen zu verwenden. Als Expertin für Wimpernverlängerung lege ich daher größten Wert auf Qualität und Haltbarkeit.",
		];

	return (
		<div className="homeServicesContainer">
			<HomeCard
				image={{
					src: video,
					alt: "Services",
					location: "right",
					type: "video",
				}}
				content={{
					header: servicesHeader,
					text: servicesText,
					button: { theme: "light", text: "Mehr" },
					extra: null,
				}}
				nav="was-biete-ich"
				settings={{
					extraPlaced: false,
					headerPlaced: true,
					buttonPlaced: true,
					imageSize: "mid",
				}}></HomeCard>
		</div>
	);
}

export default HomeServices;
