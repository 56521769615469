import HomeCard from "../../components/Card/HomeCard";
import aboutMe1 from "../../assets/about_me.jpg";
import aboutMeLogo from "../../assets/logo_big_white_no_shadow.png";

import "./index.scss";

function AboutMe() {
	const aboutMeText = [
		"Mein Name ist Katharina Gleixner und ich bin die Inhaberin von \u00C6STHETIK.. Seit meiner Ausbildung zur zertifizierten Wimpernstylistin im Jahr 2020, habe ich es mir zur Aufgabe gemacht, die natürliche Schönheit jeder Kundin hervorzuheben. Du verdienst es, Deinen eigenen, individuellen Look zu erhalten – denn Du bist wunderschön auf deine ganz eigene Weise. Dieses Ziel verfolge ich jeden Tag!",
		"Meine Passion liegt in der Schönheit und Ästhetik, weshalb ich im Jahr 2024 beschlossen habe, meiner Leidenschaft vollkommen nachzugehen und mich mit einem eigenen Wimpernstudio selbstständig zu machen. Dieser Schritt war für mich nicht nur ein beruflicher, sondern auch ein persönlicher Meilenstein.",
		"Ich bilde mich stetig weiter und lerne von den besten Lash Artists! Mir ist es wichtig, immer auf dem neuesten Stand zu bleiben und Dir die bestmögliche Qualität bieten zu können. Es erfüllt mich mit großer Freude, wenn ich sehe, wie glücklich meine Kundinnen mit ihren neuen Wimpern sind und wie ihr natürliches Strahlen noch mehr zur Geltung kommt.",
		"Mir ist es wichtig, dass Du dich bei mir rundum wohlfühlst, und ich freue mich darauf, Dich kennenzulernen!",
	];

	return (
		<div className="aboutMeContainer">
			<div className="aboutMeInnerContainer">
				<div className="aboutMeHeaderContainer">
					{/* backgroundDark */}
					<img
						className="aboutMeHeaderLogo"
						src={aboutMeLogo}
						alt="AESTHETIK. KATHARINA GLEIXNER Logo"
					/>
					{/* <img src={aboutMe1} alt="Profile" loading="lazy" decoding="async" /> */}
				</div>
				<div className="aboutMeContentContainer fontBlack">
					<HomeCard
						image={{
							src: aboutMe1,
							alt: "Katharina Gleixner",
							location: "left",
							type: "image",
						}}
						content={{
							text: aboutMeText,
						}}
						settings={{
							extraPlaced: false,
							headerPlaced: false,
							buttonPlaced: false,
							imageSize: "big",
						}}></HomeCard>
				</div>
			</div>
		</div>
	);
}

export default AboutMe;
