import example from "../../assets/home_gallery_example1.jpg";

import example1 from "../../assets/gallery_photo1.jpeg";
import example2 from "../../assets/gallery_photo2.jpeg";
import example3 from "../../assets/gallery_photo3.jpeg";
import example4 from "../../assets/gallery_photo4.jpeg";
import example5 from "../../assets/pricelist_classic.jpeg";
import example6 from "../../assets/pricelist_full.jpeg";
import example7 from "../../assets/pricelist_natural.jpeg";

import "./index.scss";

function Gallery() {
	return (
		<div className="galleryContainer">
			<div className="galleryTop">
				<div className="galleryTitle fontHeaderBig">Galerie</div>
			</div>
			<div className="galleryContentContainer">
				<div className="galleryLeft">
					<div className="galleryBig">
						<img
							src={example1}
							alt="Gallery Example"
							loading="lazy"
							decoding="async"></img>
					</div>
					<div className="gallerySmall">
						<img
							src={example2}
							alt="Gallery Example"
							loading="lazy"
							decoding="async"></img>
						<img
							src={example3}
							alt="Gallery Example"
							loading="lazy"
							decoding="async"></img>
						<img
							src={example4}
							alt="Gallery Example"
							loading="lazy"
							decoding="async"></img>
						<img
							src={example5}
							alt="Gallery Example"
							loading="lazy"
							decoding="async"></img>
					</div>
				</div>
				<div className="galleryRight">
					<div className="gallerySmall">
						<img
							src={example6}
							alt="Gallery Example"
							loading="lazy"
							decoding="async"></img>
						<img
							src={example1}
							alt="Gallery Example"
							loading="lazy"
							decoding="async"></img>
						<img
							src={example2}
							alt="Gallery Example"
							loading="lazy"
							decoding="async"></img>
						<img
							src={example3}
							alt="Gallery Example"
							loading="lazy"
							decoding="async"></img>
					</div>
					<div className="galleryBig">
						<img
							src={example7}
							alt="Gallery Example"
							loading="lazy"
							decoding="async"></img>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Gallery;
