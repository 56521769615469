import Line from "../Line";
import { type SectionHeaderType } from "../../lib/types";

import "./index.scss";

function SectionHeader({ header, theme, direction, extra }: SectionHeaderType) {
	const extraLineBeforeText =
		direction === "middle" ? (
			<>
				<Line direction="horizontal" theme={theme}></Line>
			</>
		) : (
			<></>
		);
	const font = direction === "middle" ? "" : "fontNeon fontHeader";
	const reverseOrNot = direction === "right" ? "reverseFlex" : "normalFlex";

	let renderHeader = <>{header}</>;
	if (header.includes("\u00C6STHETIK.")) {
		let splits = header.split("\u00C6STHETIK.");
		console.log(splits);
		renderHeader = (
			<>
				{splits[0]}
				<strong>
					<span>&#198;</span>STHETIK.
				</strong>
				{splits[1]}
			</>
		);
	}

	const render = (
		<>
			{extraLineBeforeText}
			<div className={`sectionHeader ${font} ${theme} ${direction} ${extra!}`}>
				{renderHeader}
			</div>
			<Line direction="horizontal" theme={theme}></Line>
		</>
	);

	return (
		<div className={`sectionHeaderContainer ${reverseOrNot}`}>{render}</div>
	);
}

export default SectionHeader;
