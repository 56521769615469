import SectionHeader from "../../components/SectionHeader";
import ServiceCard from "../../components/Card/ServiceCard";
import video from "../../assets/services_video.mp4";

import "./index.scss";

function ServiceOfferings() {
	return (
		<div className="servicesContainer">
			<div className="servicesTop">
				<div className="servicesTitle fontHeaderBig">Was Biete Ich</div>
				<div className="servicesSubtitle">
					<div className="quoteLeft">
						<SectionHeader
							header="&ldquo;"
							theme="light"
							direction="middle"
							extra="extraBig"></SectionHeader>
					</div>
					<div className="quoteText fontSubheader">
						Mein Ziel ist es,
						<br />
						<mark className="scrollHighlight">Deine Schonheit zu betonen,</mark>
						<br />
						damit Du dich in Deiner Haut wohl fülst.
					</div>
					<div className="quoteRight">
						<SectionHeader
							header="&rdquo;"
							theme="light"
							direction="middle"
							extra="extraBig"></SectionHeader>
					</div>
				</div>
			</div>
			<div className="serviceContentContainer fontBlack">
				<div className="serviceContentExtraInfo fontExtraInfo">
					Bei <span>&#198;</span>STHETIK. erwartet Dich eine hochwertige
					Behandlung, diese darauf abzielt, Deine natürliche Schönheit zu
					betonen und Dir ein strahlendes, glückliches Aussehen zu verleihen.
					Besonders wichtig ist mir, Deinen ganz eigenen, individuellen Look zu
					kreieren.
				</div>
				<div>
					<video controls autoPlay loop muted playsInline>
						<source src={video} type="video/mp4" />
					</video>
				</div>
				<div className="serviceCardsContainer">
					<ServiceCard
						content={{
							header: "Natürliche Wimpernverlängerung",
							text: [
								"Mit meiner Expertise betone ich Deine natürliche Schönheit durch eine ästhetische Wimpernverlängerung, die perfekt zu Deinem individuellen Look passt.",
							],
						}}></ServiceCard>
					<ServiceCard
						content={{
							header: "Von Classic, über Natural zu Full Aesthetic Volumen",
							text: [
								"Ob Du einen natürlichen oder voluminöseren Look bevorzugst, ich biete eine Vielzahl an Techniken, um Deine Wünsche zu erfüllen und Deine Augen zum Strahlen zu bringen.",
							],
						}}></ServiceCard>
					<ServiceCard
						content={{
							header: "Individuelle Beratung",
							text: [
								"Jeder Look ist einzigartig! In einem persönlichen Beratungsgespräch finden wir gemeinsam den idealen Stil, der Deine Persönlichkeit und Schönheit unterstreicht. Ich achte besonders auf Deine Augen- und Gesichtsform, um eine perfekte Wimpernverlängerung für Dich umzusetzen.",
							],
						}}></ServiceCard>
					<ServiceCard
						content={{
							header: "UV-System",
							text: [
								"Für langlebige und haltbare Ergebnisse setze ich auf modernste UV-Systeme. Diese Technologie sorgt dafür, dass Deine Wimpernverlängerung besonders widerstandsfähig, sowie beständig bleibt und zeitgleich pflegeleichter ist. Sie reduziert das Risiko von Allergien und Augenreizungen, was den Tragekomfort erhöht. Ich arbeite ausschließlich mit Firmen zusammen, die an dieser innovativen Technologie jahrelang geforscht haben und höchste Sicherheitsstandards erfüllen.",
							],
						}}></ServiceCard>
					<ServiceCard
						content={{
							header: "Entspannte Atmosphäre",
							text: [
								"Mein Studio bietet eine ruhige und minimalistische Umgebung, in der Du definitiv entspannen und den Alltag hinter Dir lassen kannst.",
							],
						}}></ServiceCard>
					<ServiceCard
						content={{
							header: "Hochwertige Produkte",
							text: [
								"Die Verwendung ausgewählter Premium-Produkte und Technologien, garantieren nicht nur ästhetische Ergebnisse, sondern auch langfristig die Gesundheit und Sicherheit Deiner Wimpern und Augen.",
							],
						}}></ServiceCard>
					<ServiceCard
						content={{
							header: "Nachhaltig & Vegan",
							text: [
								"Mir liegt unsere Umwelt sehr am Herzen. Deshalb verwende ich ausschließlich vegane und tierversuchsfreie Produkte, die nachhaltig verpackt sind. Ich arbeite nur mit sorgfältig ausgewählten Firmen zusammen, die meinen hohen Standards entsprechen. Über die Jahre habe ich mir ein Netzwerk aufgebaut, das Umweltfreundlichkeit und ethische Verantwortung garantiert.",
							],
						}}></ServiceCard>
					<ServiceCard
						content={{
							header: "Pflege und Nachsorge",
							text: [
								"Nach der Behandlung erhältst Du wertvolle Pflegetipps, um die Haltbarkeit Deiner Wimpernverlängerung zu maximieren, stets gepflegt auszusehen und die Gesunderhaltung Deiner natürlichen Wimpern  und Augen zu garantieren. Ich stehe meinen Kundinnen bei Fragen jederzeit zur Verfügung.",
							],
						}}></ServiceCard>
				</div>
				<div className="fontExtraInfo">
					Ich freue mich darauf, Dich in meinem Studio begrüßen zu dürfen!
				</div>
			</div>
		</div>
	);
}

export default ServiceOfferings;
