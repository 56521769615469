import SectionHeader from "../../SectionHeader";
import { type ServiceCardType } from "../../../lib/types";

import "./index.scss";

function ServiceCard({ content }: ServiceCardType) {
	const resultText = content.text.map((text) => {
		return (
			<>
				{text} <br /> <br />
			</>
		);
	});
	return (
		<div className="serviceCardContentContainer fontText fontBlack">
			<div className="serviceCardContentHeader fontSubheader">
				<SectionHeader
					header={content.header!}
					theme="dark"
					direction="middle"
					extra="short"></SectionHeader>
			</div>
			<div className="serviceCardContentText">{resultText}</div>
		</div>
	);
}

export default ServiceCard;
