import SectionHeader from "../../../components/SectionHeader";

// Fix: npm install --save @types/react-slick
// https://stackoverflow.com/questions/71548268/could-not-find-a-declaration-file-for-module-react-slick
import Slider from "react-slick";

// Fix: npm install slick-carousel --save
// https://stackoverflow.com/questions/48779212/react-slick-import-css-from-slick-carousel-fails
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import example1 from "../../../assets/gallery_photo1.jpeg";
import example2 from "../../../assets/gallery_photo2.jpeg";
import example3 from "../../../assets/gallery_photo3.jpeg";
import example4 from "../../../assets/gallery_photo4.jpeg";
import example5 from "../../../assets/pricelist_classic.jpeg";
import example6 from "../../../assets/pricelist_full.jpeg";
import example7 from "../../../assets/pricelist_natural.jpeg";

import "./index.scss";

function HomeGallery() {
	const settings = {
		className: "center",
		centerMode: true,
		infinite: true,
		centerPadding: "20px",
		slidesToShow: 3,
		speed: 500,
	};
	const settings2 = {
		infinite: true,
		slidesToShow: 1,
	};

	return (
		<>
			<div className="homeGalleryContainer">
				<SectionHeader
					header="Galerie"
					theme="light"
					direction="left"></SectionHeader>
				<div className="slider-container">
					<Slider {...settings}>
						<div className="sliderImageContainer">
							<img
								src={example1}
								alt="Home Gallery Example"
								loading="lazy"
								decoding="async"></img>
						</div>
						<div className="sliderImageContainer">
							<img
								src={example2}
								alt="Home Gallery Example"
								loading="lazy"
								decoding="async"></img>
						</div>
						<div className="sliderImageContainer">
							<img
								src={example3}
								alt="Home Gallery Example"
								loading="lazy"
								decoding="async"></img>
						</div>
						<div className="sliderImageContainer">
							<img
								src={example4}
								alt="Home Gallery Example"
								loading="lazy"
								decoding="async"></img>
						</div>
						<div className="sliderImageContainer">
							<img
								src={example5}
								alt="Home Gallery Example"
								loading="lazy"
								decoding="async"></img>
						</div>
						<div className="sliderImageContainer">
							<img
								src={example6}
								alt="Home Gallery Example"
								loading="lazy"
								decoding="async"></img>
						</div>
						<div className="sliderImageContainer">
							<img
								src={example7}
								alt="Home Gallery Example"
								loading="lazy"
								decoding="async"></img>
						</div>
					</Slider>
				</div>
				<div className="slider-container-phone">
					<Slider>
						<div className="sliderImageContainer">
							<img
								src={example1}
								alt="Home Gallery Example"
								loading="lazy"
								decoding="async"></img>
						</div>
						<div className="sliderImageContainer">
							<img
								src={example2}
								alt="Home Gallery Example"
								loading="lazy"
								decoding="async"></img>
						</div>
						<div className="sliderImageContainer">
							<img
								src={example3}
								alt="Home Gallery Example"
								loading="lazy"
								decoding="async"></img>
						</div>
						<div className="sliderImageContainer">
							<img
								src={example4}
								alt="Home Gallery Example"
								loading="lazy"
								decoding="async"></img>
						</div>
						<div className="sliderImageContainer">
							<img
								src={example5}
								alt="Home Gallery Example"
								loading="lazy"
								decoding="async"></img>
						</div>
						<div className="sliderImageContainer">
							<img
								src={example6}
								alt="Home Gallery Example"
								loading="lazy"
								decoding="async"></img>
						</div>
						<div className="sliderImageContainer">
							<img
								src={example7}
								alt="Home Gallery Example"
								loading="lazy"
								decoding="async"></img>
						</div>
					</Slider>
				</div>
			</div>
		</>
	);
}

export default HomeGallery;
